import { Box, Container, createStyles, Link, Paper, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import logo from "../../assets/images/logo.png";
import config from "../../config/config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "stretch",
            textAlign: "center",
            minHeight: "100vh",
        },
        logoBox: {
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            justifyContent: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        logoLink: {
            maxWidth: "274px",
            display: "inline-block",
            "& img": {
                width: "100%",
                height: "auto",
            },
        },
        logoText: {
            fontSize: theme.spacing(2.2),
        },
        infoBox: {
            flex: "2 1 auto",
        },
        infoPaper: {
            background: theme.palette.primary.light,
            color: theme.palette.primary.dark,
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            textAlign: "center",
            maxWidth: "240px",
        },
        infoIcon: {
            fontSize: theme.spacing(4),
            marginBottom: theme.spacing(1),
        },
        copyBox: {
            marginBottom: theme.spacing(1),
        },
    }),
);

const Mobile: React.FC = () => {
    const classes = useStyles();

    return (
        <Container className={classes.root} maxWidth="xs">
            <Box className={classes.logoBox}>
                <Box>
                    <Link
                        href={config.content.iolabsWebUrl}
                        target="_blank"
                        color="inherit"
                        className={classes.logoLink}
                    >
                        <img alt="Logo" src={logo} />
                    </Link>
                    <Typography component="p" className={classes.logoText}>
                        Embeddable Viewer App
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.infoBox}>
                <Paper className={classes.infoPaper}>
                    <InfoOutlinedIcon className={classes.infoIcon} />
                    <Typography component="p">
                        This application is optimized only for a desktop use. Please use different device.
                    </Typography>
                </Paper>
            </Box>
            <Box className={classes.copyBox}>
                <Typography component="p">
                    <Link href={config.content.iolabsWebUrl} target="_blank" color="inherit">
                        www.iolabs.ch
                    </Link>
                </Typography>
            </Box>
        </Container>
    );
};

export default Mobile;
