import { TextField } from "@material-ui/core";
import React from "react";
import { WrappedFieldProps } from "redux-form";

interface ITextFieldProps extends WrappedFieldProps {}

const renderTextField = ({ label, color, input, meta: { touched, invalid, error }, ...custom }): ITextFieldProps => (
    <div>
        <TextField
            label={label}
            placeholder={label}
            color={color}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
        />
    </div>
);

export default renderTextField;
