import { combineReducers, Reducer } from "redux";
import { reducer as appReducer } from "./app";
import { reducer as keycloackReducer } from "./keycloack";
import { reducer as treeReducer } from "./tree";
import { reducer as viewerReducer } from "./viewer";
import { reducer as notifierReducer } from "./notifier";
import { reducer as formReducer } from "redux-form";
import { Actions, State } from "./type";

export const reducer: Reducer<State, Actions> = combineReducers({
    app: appReducer,
    keycloack: keycloackReducer,
    tree: treeReducer,
    viewer: viewerReducer,
    notifier: notifierReducer,
    // TODO - add here new reducers
    form: formReducer,
});
