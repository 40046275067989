import { Button, createStyles, Dialog, IconButton, Theme, withStyles } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        dialog: {
            minWidth: "50vw",
        },
        title: {
            fontSize: "1.8rem",
            lineHeight: "1.8rem",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);

export interface ModalTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const ModalTitle = (props: ModalTitleProps) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4" component="h2" className={classes.title}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const ModalContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const ModalActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface IProps {
    children?: any;
    open: boolean;
    title?: string;
    content?: any;
    handleClose: () => void;
}

const Modal: React.FC<IProps> = props => {
    const { open, title, content, handleClose } = props;
    const classes = useStyles();

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="modal-dialog"
                open={open}
                maxWidth="lg"
                fullWidth
                scroll="paper"
                className={classes.dialog}
            >
                <ModalTitle id="modal-title" onClose={handleClose}>
                    {title}
                </ModalTitle>
                <ModalContent dividers>{content}</ModalContent>
                <ModalActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Close
                    </Button>
                </ModalActions>
            </Dialog>
        </>
    );
};

export default Modal;
