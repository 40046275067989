import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import MainPageWrapper from "../../pages/MainPage/MainPageWrapper";
import { GlobalCss } from "../../utils/Theming";
import Notifier from "../Notifier/Notifier";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        success: {
            backgroundColor: theme.palette.secondary.main,
        },
        error: {
            backgroundColor: theme.palette.error.main,
        },
        warning: {
            backgroundColor: theme.palette.error.main,
        },
        info: {
            backgroundColor: theme.palette.primary.main,
        },
    }),
);

const App: React.FC = () => {
    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={3}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
            }}
        >
            <GlobalCss />
            <MainPageWrapper />
            <Notifier />
        </SnackbarProvider>
    );
};

export default App;
