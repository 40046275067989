import { Actions } from "../type";
import { ActionTypes } from "./action";

export interface IAppState {}

const initialState: Readonly<IAppState> = {};

export function reducer(state: IAppState = initialState, action: Actions): IAppState {
    switch (action.type) {
        case ActionTypes.OnStart: {
            return { ...state };
        }

        default:
            return state;
    }
}
