import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    createStyles,
    Link,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Theme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { KeycloakProfile } from "keycloak-js";
import React, { useEffect, useRef, useState } from "react";
import { useKeycloak } from "react-keycloak";
import logo from "../../assets/images/logo.png";
import config from "../../config/config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
            },
        },
        paragraph: {
            alignSelf: "flex-start",
            textAlign: "left",
            padding: ".5rem",
            "& h1": {
                fontSize: "1.5rem",
                lineHeight: "1.5rem",
            },
            [theme.breakpoints.up("md")]: {
                marginLeft: theme.spacing(3),
                padding: ".8rem",
                "& h1": {
                    fontSize: "1.8rem",
                    lineHeight: "1.8rem",
                },
            },
        },
        menu: {
            [theme.breakpoints.up("md")]: {
                alignSelf: "flex-end",
                flex: "1 1 auto",
                textAlign: "right",
            },
        },
        logo: {
            width: "auto",
            height: "30px",
            zIndex: 2,
            "& img": {
                height: "100%",
                width: "auto",
                maxWidth: "100%",
            },
            marginTop: ".6rem",
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                marginBottom: 0,
                height: "34px",
            },
        },
        menuButton: {
            "&:hover": {
                backgroundColor: "inherit",
            },
        },
        popper: {
            zIndex: 10,
        },
        avatar: {
            color: theme.palette.white.main,
            backgroundColor: theme.palette.yellow.main,
            textTransform: "uppercase",
        },
        menuList: {
            minWidth: theme.spacing(18),
            [theme.breakpoints.up("md")]: {
                "& li": {
                    justifyContent: "flex-end",
                },
            },
        },
    }),
);

function Header() {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [userData, setUserData] = useState<KeycloakProfile | null>(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleSignOut = () => {
        keycloak.logout();
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);

    useEffect(() => {
        Promise.resolve(keycloak.loadUserProfile()).then(data => {
            setUserData(data as KeycloakProfile);
        });

        if (prevOpen.current && !open) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [keycloak, open]);

    return (
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" className={classes.root}>
            <Box className={classes.logo}>
                <Link href={config.content.iolabsWebUrl} target="_blank" color="inherit">
                    <img alt="Logo" src={logo} />
                </Link>
            </Box>

            <Box className={classes.paragraph}>
                <Typography variant="h4" component="h1">
                    Embeddable Viewer App
                </Typography>
            </Box>

            {userData && (
                <Box className={classes.menu}>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        variant="text"
                        onClick={handleToggle}
                        className={classes.menuButton}
                    >
                        <Avatar className={classes.avatar}>
                            {`${userData!.firstName!.charAt(0)}${userData!.lastName!.charAt(0)}`}
                        </Avatar>
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorRef.current}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        className={classes.popper}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    className={classes.menuList}
                                >
                                    <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover>
                </Box>
            )}
        </Box>
    );
}

export default Header;
