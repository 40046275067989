/**
 * Merge new loaded data from server to tree state
 * @param nodeId
 * @param nodesToMerge
 * @param object
 */
export const mergeNodesToTreeState = (nodeId: string, nodesToMerge: any, object: any): any => {
    if (Array.isArray(object)) {
        object.forEach(node => {
            if (node.id === nodeId) {
                // set parent node as loaded
                node.loaded = true;
                if (node.children === undefined) {
                    node.children = [];
                    node.children.push(...nodesToMerge);
                } else {
                    node.children.push(...nodesToMerge);
                }
            } else {
                if (node.children) {
                    for (let i = 0; i < node.children.length; i++) {
                        node.children[i] = mergeNodesToTreeState(nodeId, nodesToMerge, node.children[i]);
                    }
                }
            }
        });
    } else {
        if (object.id === nodeId) {
            // set parent node as loaded
            object.loaded = true;
            if (object.children === undefined) {
                object.children = [];
                object.children.push(...nodesToMerge);
            } else {
                object.children.push(...nodesToMerge);
            }
        } else {
            if (object.children) {
                for (let i = 0; i < object.children.length; i++) {
                    object.children[i] = mergeNodesToTreeState(nodeId, nodesToMerge, object.children[i]);
                }
            }
        }
    }

    return object;
};

/**
 * Set new object property loaded to true after loading new node tree data
 * @param data
 */
export const setDataAsLoaded = (data: any): any => {
    if (Array.isArray(data)) {
        data.forEach(node => {
            if (node.children && node.children.length > 0) {
                node.children.map(setDataAsLoaded);
            }
        });
    } else {
        if (data.children && data.children.length > 0) {
            data.children.map(setDataAsLoaded);
        }
    }

    return data;
};
