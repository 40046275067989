import { createAction } from "../action";
import { ActionsUnion, DispatchAction } from "../type";

export enum ActionTypes {
    OnSelectUrn = "viewer/ON_SELECT_URN",
    OnIsolateIds = "viewer/ON_ISOLATE_IDS",
}

export type SelectUrnOptions = {
    projectId: string;
    urn: string;
    urnLatest: string;
    fileName: string;
};

export type IsolateIdsOptions = {
    isolateIds: number[];
};

export const Actions = {
    onSelectUrn: (options: SelectUrnOptions) => createAction(ActionTypes.OnSelectUrn, options),
    onIsolateIds: (options: IsolateIdsOptions) => createAction(ActionTypes.OnIsolateIds, options),
};

export type Actions = ActionsUnion<typeof Actions>;

export function onSelectUrn(options: SelectUrnOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onSelectUrn(options));
    };
}

export function onIsolateIds(options: IsolateIdsOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onIsolateIds(options));
    };
}
