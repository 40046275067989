import Keycloak from "keycloak-js";
import React, { Component, ReactNode } from "react";
import { KeycloakProvider } from "react-keycloak";
import { connect } from "react-redux";
import config from "../../config/config";
import { Dispatch } from "../type";
import { onEvent, OnEventOptions, onTokens, OnTokensOptions } from "./action";

const keycloak: Keycloak.KeycloakInstance = Keycloak({
    clientId: config.security.clientId,
    realm: config.security.realm,
    url: config.security.realmUrl,
});

const keycloakInitConfig: Keycloak.KeycloakInitOptions = {
    // onLoad: "login-required",
};

const mapDispatch = (dispatch: Dispatch) => ({
    onEvent: (options: OnEventOptions) => dispatch(onEvent(options)),
    onTokens: (options: OnTokensOptions) => dispatch(onTokens(options)),
});

interface IProps {
    children: ReactNode;
    onEvent: (options: OnEventOptions) => void;
    onTokens: (options: OnTokensOptions) => void;
}

class SecurityProvider extends Component<IProps> {
    render() {
        const { children, onEvent, onTokens } = this.props;

        return (
            <KeycloakProvider
                keycloak={keycloak}
                initConfig={keycloakInitConfig}
                onEvent={event =>
                    onEvent({
                        event: event,
                    })
                }
                onTokens={tokens => {
                    onTokens(tokens);
                }}
            >
                {children}
            </KeycloakProvider>
        );
    }
}

export default connect(null, mapDispatch)(SecurityProvider);
