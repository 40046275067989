import { Box, Button, Container, createStyles, Grid, Link, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useKeycloak } from "react-keycloak";
import logo from "../../assets/images/logo.png";
import config from "../../config/config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100vh",
        },
        container: {
            height: "100%",
        },
        gridLogo: {
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.up("sm")]: {
                display: "flex",
                justifyContent: "flex-end",
            },
        },
        gridHeading: {
            [theme.breakpoints.up("sm")]: {
                "& h1": {
                    fontSize: "1.4rem",
                    lineHeight: "1.8rem",
                },
            },
            [theme.breakpoints.up("md")]: {
                "& h1": {
                    fontSize: "1.8rem",
                    lineHeight: "2.2rem",
                },
            },
        },
        gridLogin: {
            [theme.breakpoints.up("sm")]: {
                marginLeft: "50%",
            },
        },
        gridCopy: {
            [theme.breakpoints.up("sm")]: {
                marginLeft: "50%",
            },
        },
        box: {
            padding: theme.spacing(1),
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                textAlign: "inherit",
            },
        },
        logo: {
            width: "100%",
            maxWidth: "250px",
            "& img": {
                height: "auto",
                width: "100%",
            },
        },
        loginButton: {
            width: "100%",
            maxWidth: "230px",
        },
        paragraph: {
            fontSize: "0.8rem",
        },
        favouriteIcon: {
            fontSize: "1.1rem",
            marginBottom: "-.2rem",
            color: theme.palette.error.main,
        },
    }),
);

const LoginPage: React.FC = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();

    const loginHandler = () => {
        keycloak.login();
    };

    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
        <Container className={classes.root} maxWidth="md">
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                spacing={1}
                className={classes.container}
            >
                <Grid item xs={12} sm={6} className={classes.gridLogo}>
                    <Box className={`${classes.box} ${classes.logo}`}>
                        <Link href={config.content.iolabsWebUrl} target="_blank" color="inherit">
                            <img alt="Logo" src={logo} />
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridHeading}>
                    <Box className={classes.box}>
                        <Typography variant="h5" component="h1">
                            Welcome to the <br /> Embeddable Viewer App
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridLogin}>
                    <Box className={classes.box}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.loginButton}
                            onClick={loginHandler}
                        >
                            Login
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridCopy}>
                    <Box className={classes.box}>
                        <Typography component="p" className={classes.paragraph}>
                            <Link href={config.content.iolabsWebUrl} target="_blank" color="inherit">
                                Copyright © {getYear()} - ioLabs Swiss GmbH
                            </Link>
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            <Link href={config.content.iolabsWebUrl} target="_blank" color="inherit">
                                Made with <FavoriteIcon className={classes.favouriteIcon} /> in Zürich
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LoginPage;
