import ApiClient from "../../api/rest/ApiClient";
import { onStart } from "../app";
import { Actions } from "./action";
import { selectEvent, selectToken } from "./selector";

// @ts-ignore
const onKeycloackEventMiddleware = ({ dispatch, getState }) => next => (action: Actions) => {
    const resolve = next(action);

    if (action.type === "keycloak/ON_EVENT") {
        const event = selectEvent(getState());
        if (event === "onReady") {
            dispatch(onStart());
        }
    }
    if (action.type === "keycloak/ON_TOKENS") {
        const token = selectToken(getState());
        ApiClient.setAuthorizationToken(token);
    }

    return resolve;
};

export default [onKeycloackEventMiddleware];
