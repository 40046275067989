import { treeDataDepth } from "../../api/rest/data/tree/client";
import { onLoadData, onLoadDataDone, onLoadDataFail } from "../tree";
import { Actions } from "./action";

// @ts-ignore
const onAppStartMiddleware = ({ dispatch }) => next => (action: Actions) => {
    if (action.type === "app/ON_START") {
        dispatch(onLoadData);
        treeDataDepth(2)
            .then(data => {
                return dispatch(onLoadDataDone(data));
            })
            .catch(error => {
                dispatch(onLoadDataFail(error));
            });
    }

    return next(action);
};

export default [onAppStartMiddleware];
