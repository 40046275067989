import { ITreeFail, ITreeResponse } from "../../api/rest/data/tree/types";
import { createAction } from "../action";
import { ActionsUnion, DispatchAction } from "../type";

export enum ActionTypes {
    OnLoadData = "tree/ON_LOAD_DATA",
    OnLoadDataDone = "tree/ON_LOAD_DATA_DONE",
    OnLoadDataFail = "tree/ON_LOAD_DATA_FAIL",
    OnLoadNode = "tree/ON_LOAD_NODE",
    OnLoadNodeDone = "tree/ON_LOAD_NODE_DONE",
    OnLoadNodeFail = "tree/ON_LOAD_NODE_FAIL",
}

export type SelectUrnOptions = {
    urn: string;
};

export type OnLoadNodeOptions = {
    nodeId: string;
    depth: number;
};

export const Actions = {
    onLoadData: () => createAction(ActionTypes.OnLoadData),
    onLoadDataDone: (options: ITreeResponse) => createAction(ActionTypes.OnLoadDataDone, options),
    onLoadDataFail: (options: ITreeFail) => createAction(ActionTypes.OnLoadDataFail, options),
    onLoadNode: (options: OnLoadNodeOptions) => createAction(ActionTypes.OnLoadNode, options),
    onLoadNodeDone: (options: ITreeResponse) => createAction(ActionTypes.OnLoadNodeDone, options),
    onLoadNodeFail: (options: ITreeFail) => createAction(ActionTypes.OnLoadNodeFail, options),
};

export type Actions = ActionsUnion<typeof Actions>;

export function onLoadData(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onLoadData());
    };
}

export function onLoadDataDone(options: ITreeResponse): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onLoadDataDone(options));
    };
}

export function onLoadDataFail(options: ITreeFail): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onLoadDataFail(options));
    };
}

export function onLoadNode(options: OnLoadNodeOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onLoadNode(options));
    };
}

export function onLoadNodeDone(options: ITreeResponse): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onLoadNodeDone(options));
    };
}

export function onLoadNodeFail(options: ITreeFail): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onLoadNodeFail(options));
    };
}
