export type EnvType = "test" | "development" | "production";

export interface IConfig {
    envType: EnvType;
    security: {
        clientId: string;
        realm: string;
        realmUrl: string;
    };
    api: {
        baseUrl: string;
        forgeProxyUrl: string;
    };
    content: {
        iolabsWebUrl: string;
        liveDemoUrl: string;
        embeddableDemoUrl: string;
    };
}

const config: IConfig = {
    envType: process.env.REACT_APP_ENV_TYPE as EnvType,
    security: {
        clientId: process.env.REACT_APP_SECURITY_CLIENT_ID as string,
        realm: process.env.REACT_APP_SECURITY_REALM_NAME as string,
        realmUrl: process.env.REACT_APP_SECURITY_REALM_URL as string,
    },
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL as string,
        forgeProxyUrl: process.env.REACT_APP_API_FORGE_PROXY_URL as string,
    },
    content: {
        iolabsWebUrl: process.env.REACT_APP_IOLABS_WEB_URL as string,
        liveDemoUrl: process.env.REACT_APP_DEMO_URL as string,
        embeddableDemoUrl: process.env.REACT_APP_EMBEDDABLE_URL as string,
    },
};

export default config;
