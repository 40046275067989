import { createSelector } from "reselect";
import { State } from "../type";
import { IKeycloakState } from "./reducer";

export function selectKeycloakState(state: State): IKeycloakState {
    return state.keycloack;
}

export const selectKIdToken = createSelector(
    selectKeycloakState,
    state => state.idToken,
);

export const selectRefreshToken = createSelector(
    selectKeycloakState,
    state => state.refreshToken,
);

export const selectToken = createSelector(
    selectKeycloakState,
    state => state.token,
);

export const selectEvent = createSelector(
    selectKeycloakState,
    state => state.event,
);
