import { withStyles } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const GlobalCss = withStyles({
    "@global": {
        // forge viewer overrides
        ".adsk-viewing-viewer.dark-theme .adsk-button.active, .adsk-viewing-viewer.dark-theme .adsk-button:focus": {
            color: "#3b8fcd !important",
        },

        ".adsk-viewing-viewer.dark-theme .adsk-button:hover": {
            color: "#3b8fcd !important",
            border: "1px solid #3b8fcd !important",
        },
        // hide viewer toolbar on mobile devices
        "@media screen and (max-width: 1279px)": {
            ".adsk-viewing-viewer .adsk-toolbar": {
                display: "none !important",
            },
        },
    },
})(() => null);

const iolabsTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#3b8fcd",
            dark: "#072586",
            light: "#e8edff",
        },
        secondary: {
            main: "#b2c609",
            light: "rgba(0,0,0,0.08)",
        },
        error: {
            main: "#ff0000",
        },
        white: {
            main: "#fff",
        },
        yellow: {
            main: "#f2a32e",
        },
        black: {
            main: "#000",
        },
        border: {
            main: "#ccc",
            dark: "#00000042",
        },
        background: {
            default: "#fff",
        },
    },
    typography: {
        h5: {
            fontSize: "1.2rem",
            lineHeight: 1,
        },
    },
    overrides: {
        MuiCollapse: {
            wrapperInner: {
                width: "300%",
            },
        },
    },
    spacing: factor => `${0.6 * factor}rem`, // bootstrap strategy
});

export default iolabsTheme;
