import axios, { AxiosRequestConfig } from "axios";

export const createClient = (options: AxiosRequestConfig = {}) => {
    const client = axios.create(options);

    // Add a request interceptor
    client.interceptors.request.use(
        requestConfig => requestConfig,
        requestError => {
            console.error(requestError); // tslint:disable-line no-console

            return Promise.reject(requestError);
        },
    );

    // Add a response interceptor
    client.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status >= 500) {
                console.error(error); // tslint:disable-line no-console
            }

            return Promise.reject(error);
        },
    );

    return client;
};
