export type UrlParam = {
    [key: string]: string;
};

/**
 * Parse params from URL
 * @param search
 */
export const getUrlParams = (search: string): UrlParam => {
    const hashes = search.slice(search.indexOf("?") + 1).split("&");
    const params = {};
    hashes.map(hash => {
        const [key, val] = hash.split("=");
        params[key] = decodeURIComponent(val);
    });
    return params;
};
