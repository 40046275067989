import { Actions } from "../type";
import { ActionTypes } from "./action";

export interface IViewerState {
    projectId: string;
    urn: string;
    urnLatest: string;
    fileName: string;
    isolateIds: number[];
}

export const initialState: Readonly<IViewerState> = {
    projectId: "",
    urn: "",
    urnLatest: "",
    fileName: "",
    isolateIds: [],
};

export function reducer(state: IViewerState = initialState, action: Actions): IViewerState {
    switch (action.type) {
        case ActionTypes.OnSelectUrn: {
            const { projectId, urn, urnLatest, fileName } = action.payload;
            return { ...state, projectId, urn, urnLatest, fileName };
        }

        case ActionTypes.OnIsolateIds: {
            const { isolateIds } = action.payload;
            return { ...state, isolateIds };
        }

        default:
            return state;
    }
}
