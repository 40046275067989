import { treeDataNode } from "../../api/rest/data/tree/client";
import { ITree } from "../../api/rest/data/tree/types";
import { mergeNodesToTreeState, setDataAsLoaded } from "../../components/Tree/Node";
import { addNotification, INotification } from "../notifier";
import { Actions, onLoadNodeDone, onLoadNodeFail } from "./action";
import { selectData } from "./selector";

// @ts-ignore
const onTreeNodeMiddleware = ({ dispatch, getState }) => next => (action: Actions) => {
    const resolve = next(action);

    if (action.type === "tree/ON_LOAD_NODE") {
        const { nodeId, depth } = action.payload;
        treeDataNode(nodeId, depth)
            .then(data => {
                if (Array.isArray(data) && !data.length) {
                    const notification: INotification = {
                        key: 1,
                        variant: "info",
                        message: "There are no data to show in viewer",
                    };
                    dispatch(addNotification({ notification }));
                }

                // select tree state
                const treeData = selectData(getState());

                // set new data as loaded
                data = setDataAsLoaded(data);

                // merge new data from server to specific place in tree
                const mergedTreeData = mergeNodesToTreeState(nodeId, (data as unknown) as ITree, treeData);

                dispatch(onLoadNodeDone(mergedTreeData));
            })
            .catch(error => {
                dispatch(onLoadNodeFail(error));
            });
    }

    return resolve;
};

export default [onTreeNodeMiddleware];
