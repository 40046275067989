export const encodeUrnToBase64 = (urn: string): string => {
    return window
        .btoa(urn)
        .replace(/=/g, "")
        .replace(/\//g, "_");
};

export const decodeUrnFromBase64 = (urnInBase64: string): string => {
    return window.atob(urnInBase64);
};

export const isEmea = (urn: string): boolean => {
    return urn.indexOf("emea") > -1;
};
