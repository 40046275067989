import { createSelector } from "reselect";
import { State } from "../type";
import { IViewerState } from "./reducer";

export function selectViewerState(state: State): IViewerState {
    return state.viewer;
}

export const selectProjectId = createSelector(selectViewerState, state => state.projectId);

export const selectUrn = createSelector(selectViewerState, state => state.urn);
export const selectUrnLatest = createSelector(selectViewerState, state => state.urnLatest);

export const selectFileName = createSelector(selectViewerState, state => state.fileName);

export const selectIsolateIds = createSelector(selectViewerState, state => state.isolateIds);
