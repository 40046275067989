import { Actions, ActionTypes, INotification } from "./action";

export interface INotifierState {
    nextNotification: number;
    notifications: INotification[];
}

const initialState: Readonly<INotifierState> = {
    nextNotification: -1, // used for notifications keys
    notifications: [],
};

export function reducer(state: INotifierState = initialState, action: Actions): INotifierState {
    switch (action.type) {
        case ActionTypes.AddNotification: {
            let key = state.nextNotification + 1;

            return {
                ...state,
                nextNotification: key,
                // add notification with incremented key at the start of the list
                notifications: [
                    {
                        ...action.payload.notification,
                        key: key,
                    },
                    ...state.notifications,
                ],
            };
        }

        case ActionTypes.RemoveNotification: {
            return {
                ...state,
                // remove notification from the list for given key
                notifications: state.notifications.filter(notification => notification.key !== action.payload.key),
            };
        }

        default:
            return state;
    }
}
