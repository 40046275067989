import { Box, Button, createStyles, Paper, Theme, Tab, Tabs } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import magula from "react-syntax-highlighter/dist/esm/styles/hljs/magula";
import config from "../../config/config";
import ExportForm, { Version } from "../../forms/ExportForm/ExportForm";
import { useExportForm } from "../../redux/form/hook";
import { addNotification, INotification, NotificationVariant } from "../../redux/notifier";
import { Dispatch } from "../../redux/type";
import { useProjectId, useUrn, useUrnLatest } from "../../redux/viewer";
import Tutorial from "./Tutorial";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: "1.5rem",
        },
        logo: {
            width: "180px",
            height: "auto",
        },
        exportBox: {
            padding: theme.spacing(1),
            position: "relative",
            height: "100%",
        },
        paperInput: {
            background: theme.palette.secondary.light,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1),
            flex: "0 1 auto",
        },
        paperDemo: {
            background: theme.palette.secondary.light,
            marginBottom: theme.spacing(1),
            flex: "0 1 auto",
        },
        paperOutput: {
            position: "relative",
            background: theme.palette.secondary.light,
            flex: "1 3 auto",
            overflowY: "hidden",
        },
        outputCode: {
            height: "99%",
            padding: theme.spacing(2),
            overflow: "auto",
        },
        textarea: {
            minWidth: "100%",
            maxWidth: "100%",
        },
        minimalBtn: {
            marginLeft: theme.spacing(2),
        },
        tabPanel: {
            paddingTop: theme.spacing(2),
        },
    }),
);

interface IProps {
    children?: any;
    onSubmit?: any;
}

enum File {
    ZIP = "iolabs-viewer.1.0.0.zip",
}

const Export: React.FC<IProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch<Dispatch>();
    const projectId = useProjectId();
    const urn = useUrn();
    const urnLatest = useUrnLatest();
    const exportForm = useExportForm();

    const [outputProjectId, setOutputProjectId] = useState<string>("");
    const [outputUrn, setOutputUrn] = useState<string>("");
    const [outputLatest, setOutputLatest] = useState<boolean>(false);
    const [outputLogo, setOutputLogo] = useState<string | null>(null);
    const [generated, setGenerated] = useState<boolean>(false);

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const [outputMode, setOutputMode] = React.useState(0);

    useEffect(() => {
        if (exportForm !== undefined) {
            const values = exportForm?.values;

            if (urn) {
                setOutputProjectId(projectId);
                setOutputUrn(values.version === Version.CURRENT ? urn : urnLatest);
                setOutputLatest(values.version === Version.LATEST);
                setOutputLogo(values.logo ? values.logo : null);
                setGenerated(true);
            } else {
                setGenerated(false);
            }
        }
    }, [exportForm, setOutputLogo, setOutputLatest, setGenerated, urn, urnLatest]);

    const handleSubmit = () => {};

    const handleDownload = (file: File): void => {
        const href = process.env.PUBLIC_URL + `/viewer/${file}`;
        let link = document.createElement("a");
        link.href = href;
        link.download = `${file}`;
        link.click();

        handleNotification(`Your file "${file}" was successfully downloaded.`);
    };

    const handleOpenDialog = (): void => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (): void => {
        setOpenDialog(false);
    };

    const handleNotification = (message: string, variant: NotificationVariant = "success"): void => {
        const notification: INotification = {
            key: new Date().getTime() + Math.random(),
            variant: variant,
            message,
        };
        dispatch(addNotification({ notification }));
    };

    const handleChangeOutputMode = (event, newValue) => {
        setOutputMode(newValue);
    };

    const TabPanel = props => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && children}
            </div>
        );
    };

    const div = `<div id="iolabs__viewer"></div>`;
    let code: string;
    let embedCode: string = `<iframe src="${window.location.origin}${
        config.content.embeddableDemoUrl
    }?projectId=${projectId}&urn=${encodeURIComponent(outputUrn)}" width="100%" height="100%"></iframe>`;

    if (outputLogo !== null && !outputLatest) {
        code = `iolabs.viewer.init({\n    projectId: "${outputProjectId}",\n    urn: "${outputUrn}",\n    logo: "${outputLogo}",\n    divId: "iolabs__viewer"\n});`;
    } else if (outputLogo !== null && outputLatest) {
        code = `iolabs.viewer.init({\n    projectId: "${outputProjectId}",\n    urn: "${outputUrn}",\n    latest: true,\n    logo: "${outputLogo}",\n    divId: "iolabs__viewer"\n});`;
    } else if (outputLogo === null && !outputLatest) {
        code = `iolabs.viewer.init({\n    projectId: "${outputProjectId}",\n    urn: "${outputUrn}",\n    divId: "iolabs__viewer"\n});`;
    } else {
        code = `iolabs.viewer.init({\n    projectId: "${outputProjectId}",\n    urn: "${outputUrn}",\n    latest: true,\n    divId: "iolabs__viewer"\n});`;
    }

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                alignContent="stretch"
                p={2}
                className={classes.exportBox}
            >
                <Paper className={classes.paperInput}>
                    <Typography component="h1" paragraph={true}>
                        <strong>Input:</strong>
                    </Typography>
                    <ExportForm onSubmit={handleSubmit} handleOpenDialog={handleOpenDialog} {...props} />
                </Paper>

                <Paper className={classes.paperOutput}>
                    <Box className={classes.outputCode}>
                        <Typography component="h1" paragraph={true}>
                            <strong>Output:</strong>
                        </Typography>

                        <Typography variant="caption" display="block" gutterBottom>
                            Live demo:
                        </Typography>
                        <Box mt={1} mb={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!generated}
                                href={`${config.content.liveDemoUrl}?projectId=${projectId}&urn=${encodeURIComponent(
                                    outputUrn,
                                )}`}
                                target="_blank"
                            >
                                Visit sample page
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                disabled={!generated}
                                className={classes.minimalBtn}
                                href={`${
                                    config.content.embeddableDemoUrl
                                }?projectId=${projectId}&urn=${encodeURIComponent(outputUrn)}`}
                                target="_blank"
                            >
                                Minimal
                            </Button>
                        </Box>
                        <Tabs
                            value={outputMode}
                            onChange={handleChangeOutputMode}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Manual" />
                            <Tab label="Embed" />
                        </Tabs>
                        <TabPanel value={outputMode} index={0} className={classes.tabPanel}>
                            <Typography variant="caption" display="block" gutterBottom>
                                Viewer script and style file:
                            </Typography>
                            <Box mt={1} mb={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!generated}
                                    onClick={() => handleDownload(File.ZIP)}
                                >
                                    Download ZIP archive
                                </Button>
                            </Box>

                            <Typography variant="caption" display="block" gutterBottom>
                                Viewer element:
                            </Typography>
                            {generated ? (
                                <SyntaxHighlighter language="html" style={magula}>
                                    {div}
                                </SyntaxHighlighter>
                            ) : (
                                <Box mt={-1}>
                                    <Skeleton variant="text" width="100%" height={40} />
                                </Box>
                            )}
                            <Box mt={1} mb={3}>
                                <CopyToClipboard
                                    text={div}
                                    onCopy={() => {
                                        handleNotification("Viewer element copied to clipboard.");
                                    }}
                                >
                                    <Button variant="contained" color="primary" disabled={!generated}>
                                        Copy code to clipboard
                                    </Button>
                                </CopyToClipboard>
                            </Box>

                            <Typography variant="caption" display="block" gutterBottom>
                                Script configuration:
                            </Typography>
                            {generated ? (
                                <SyntaxHighlighter language="javascript" style={magula}>
                                    {code}
                                </SyntaxHighlighter>
                            ) : (
                                <Box mt={-1}>
                                    <Skeleton variant="text" width="100%" height={80} />
                                </Box>
                            )}
                            <Box mt={1}>
                                <CopyToClipboard
                                    text={code}
                                    onCopy={() => {
                                        handleNotification("Script configuration copied to clipboard.");
                                    }}
                                >
                                    <Button variant="contained" color="primary" disabled={!generated}>
                                        Copy code to clipboard
                                    </Button>
                                </CopyToClipboard>
                            </Box>
                        </TabPanel>
                        <TabPanel value={outputMode} index={1} className={classes.tabPanel}>
                            <Typography variant="caption" display="block" gutterBottom>
                                HTML embed code:
                            </Typography>
                            {generated ? (
                                <SyntaxHighlighter language="javascript" style={magula}>
                                    {embedCode}
                                </SyntaxHighlighter>
                            ) : (
                                <Box mt={-1}>
                                    <Skeleton variant="text" width="100%" height={80} />
                                </Box>
                            )}

                            <Box mt={1} mb={3}>
                                <CopyToClipboard
                                    text={embedCode}
                                    onCopy={() => {
                                        handleNotification("Embeddable HTML code copied to clipboard.");
                                    }}
                                >
                                    <Button variant="contained" color="primary" disabled={!generated}>
                                        Copy code to clipboard
                                    </Button>
                                </CopyToClipboard>
                            </Box>
                        </TabPanel>
                    </Box>
                </Paper>
            </Box>

            <Tutorial open={openDialog} handleClose={handleCloseDialog} />
        </>
    );
};

export default Export;
