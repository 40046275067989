import { createAction } from "../action";
import { ActionsUnion, DispatchAction } from "../type";

export enum ActionTypes {
    OnStart = "app/ON_START",
}

export const Actions = {
    onStart: () => createAction(ActionTypes.OnStart),
};

export type Actions = ActionsUnion<typeof Actions>;

export function onStart(): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onStart());
    };
}
