import { createAction } from "../action";
import { ActionsUnion, DispatchAction } from "../type";

export enum ActionTypes {
    AddNotification = "notifier/ADD_NOTIFICATION",
    RemoveNotification = "notifier/REMOVE_NOTIFICATION",
}

export type NotificationVariant = "default" | "success" | "error" | "info" | undefined;

export interface INotification {
    key: number;
    message: string;
    variant?: NotificationVariant;
    persist?: boolean;
}

export interface AddNotificationOptions {
    notification: INotification;
}

export type RemoveNotificationOptions = {
    key: number;
};

export const Actions = {
    addNotification: (options: AddNotificationOptions) => createAction(ActionTypes.AddNotification, options),
    removeNotification: (options: RemoveNotificationOptions) => createAction(ActionTypes.RemoveNotification, options),
};

export type Actions = ActionsUnion<typeof Actions>;

export function addNotification(options: AddNotificationOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.addNotification(options));
    };
}

export function removeNotification(options: RemoveNotificationOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.removeNotification(options));
    };
}
