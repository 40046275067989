import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import config from "../config/config";
import { reducer } from "./reducer";
import { Store } from "./type";

import appMiddleware from "../redux/app/middleware";
import keycloakMiddleware from "../redux/keycloack/middleware";
import treeMiddleware from "../redux/tree/middleware";

// add Redux debugging tool as Chrome extension when development environment is active
const composeEnhancers =
    config.envType === "development" ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const middlewares = [
    ...appMiddleware,
    ...keycloakMiddleware,
    ...treeMiddleware,
    // TODO: add here new middlewares
    thunkMiddleware,
];

const store: Store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

type Props = {
    children: ReactNode;
};

export function StoreProvider(props: Props): JSX.Element {
    const { children } = props;
    return <Provider store={store}>{children}</Provider>;
}
