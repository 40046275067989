import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeNotification } from "../../redux/notifier";
import { useNotifications } from "../../redux/notifier/hook";
import { Dispatch } from "../../redux/type";

interface IProps {
    children?: any;
}

const Notifier: React.FC<IProps> = () => {
    const dispatch = useDispatch<Dispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const notifications = useNotifications();

    const [displayed, setDisplayed] = useState<number[]>([]);

    const notifier = notifications.map(notification => {
        setTimeout(() => {
            // if notification already displayed, abort
            if (displayed.filter(key => key === notification.key).length > 0) {
                return;
            }

            // display notification using Snackbar
            enqueueSnackbar(notification.message, { variant: notification.variant });

            // add notification's key to the local state
            setDisplayed([...displayed, notification.key]);

            // dispatch action to remove the notification from the redux store
            dispatch(removeNotification({ key: notification.key }));
        }, 1000);
        return true;
    });

    return <>{notifier}</>;
};

export default Notifier;
