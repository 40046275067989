import { FormErrors } from "redux-form";
import { IExportFormData } from "./type";

const validate = (values: IExportFormData): FormErrors<IExportFormData> => {
    const errors: FormErrors<IExportFormData> = {};

    if (!values.version) {
        errors.version = "Please choose embedded version.";
    }

    return errors;
};

export default validate;
