import { createSelector } from "reselect";
import { State } from "../type";
import { ITreeState } from "./reducer";

export function selectTreeState(state: State): ITreeState {
    return state.tree;
}
export const selectLoading = createSelector(selectTreeState, state => state.loading);

export const selectData = createSelector(selectTreeState, state => state.data);
