import { AxiosResponse } from "axios";
import ApiClient from "../../ApiClient";
import { ITreeResponse } from "./types";

const treeApi = new ApiClient("/datamanagement");

export const treeDataAll = (): Promise<ITreeResponse> =>
    treeApi.get("/tree").then((response: AxiosResponse<ITreeResponse>) => {
        return Promise.resolve(response.data);
    });

export const treeDataDepth = (depth: number): Promise<ITreeResponse> =>
    treeApi.get(`/tree?depth=${depth}`).then((response: AxiosResponse<ITreeResponse>) => {
        return Promise.resolve(response.data);
    });

export const treeDataNode = (nodeId: string, depth: number): Promise<ITreeResponse> =>
    treeApi.get(`/tree?nodeId=${nodeId}&depth=${depth}`).then((response: AxiosResponse<ITreeResponse>) => {
        return Promise.resolve(response.data);
    });
