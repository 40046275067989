import { Container, createStyles, Grid, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { isMobile } from "react-device-detect";
import { useKeycloak } from "react-keycloak";
import Export from "../../components/Export/Export";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Tree from "../../components/Tree/Tree";
import Viewer from "../../components/Viewer/Viewer";
import LoginPage from "../LoginPage/LoginPage";
import Mobile from "./Mobile";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { flexGrow: 1, padding: theme.spacing(1) },
        header: {
            textAlign: "center",
            padding: ".5rem",
        },
        footer: {
            minHeight: "2rem",
            padding: ".5rem",
        },
        gridItemTree: {
            height: "40vh",
            [theme.breakpoints.up("lg")]: {
                height: "calc(100vh - 148px)",
            },
        },
        gridItemViewer: {
            height: "70vh",
            [theme.breakpoints.up("sm")]: {
                height: "80vh",
            },
            [theme.breakpoints.up("md")]: {
                height: "90vh",
            },
            [theme.breakpoints.up("lg")]: {
                height: "calc(100vh - 148px)",
            },
        },
        gridItemExport: {
            [theme.breakpoints.up("lg")]: {
                height: "calc(100vh - 148px)",
            },
        },
        tree: {
            maxHeight: "100%",
            overflow: "auto",
        },
        headerContainer: {},
        mainContainer: {
            marginTop: theme.spacing(0.5),
        },
        footerContainer: {
            marginTop: theme.spacing(0.5),
        },
    }),
);

const MainPage: React.FC = () => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();

    return (
        <>
            {keycloak.authenticated ? (
                <>
                    {isMobile ? (
                        <Mobile />
                    ) : (
                        <Container className={classes.root} maxWidth={false}>
                            <Grid container spacing={1} className={classes.headerContainer}>
                                <Grid item xs={12}>
                                    <Paper className={classes.header}>
                                        <Header />
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className={classes.mainContainer}>
                                <Grid item xs={12} sm={12} lg={3} xl={2}>
                                    <Paper className={`${classes.gridItemTree} ${classes.tree}`}>
                                        <Tree />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6} xl={8}>
                                    <Paper className={`${classes.gridItemViewer}`}>
                                        <Viewer />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} xl={2}>
                                    <Paper className={`${classes.gridItemExport}`}>
                                        <Export />
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className={classes.footerContainer}>
                                <Grid item xs={12}>
                                    <Paper className={classes.footer}>
                                        <Footer />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                </>
            ) : (
                <LoginPage />
            )}
        </>
    );
};

export default MainPage;
