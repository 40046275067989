import { CssBaseline } from "@material-ui/core";
import React, { ReactNode, useEffect, useState } from "react";
import { getUrlParams } from "../../utils/Url";
import MainPage from "./MainPage";
import Modal from "./Modal";

enum AuthError {
    AccessDenied = "access_denied",
}

const MainPageWrapper: React.FC = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<string>("");
    const [modalContent, setModalContent] = useState<any>();

    const handleOpenModal = (title: string, content: ReactNode): void => {
        setOpenModal(true);
        setModalTitle(title);
        setModalContent(content);
    };

    const handleCloseModal = (): void => {
        setOpenModal(false);
        setModalTitle("");
        setModalContent(null);
    };

    useEffect(() => {
        const hash = window.location.hash.replace("#", "?");
        const params = getUrlParams(hash);
        window.location.hash = "";

        if (params?.auth_error) {
            if (params?.auth_error === AuthError.AccessDenied) {
                handleOpenModal("Authorization Error", "Access denied message");
            } else {
                handleOpenModal("Authorization Error", "General error message");
            }
        }
    }, []);

    return (
        <>
            <CssBaseline />
            <MainPage />
            <Modal open={openModal} title={modalTitle} content={modalContent} handleClose={handleCloseModal} />
        </>
    );
};

export default MainPageWrapper;
