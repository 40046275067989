import { Box, Button, createStyles, Radio, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { useUrn } from "../../redux/viewer";
import renderRadioExportVersion from "../renderRadioExportVersion";
import renderTextField from "../renderTextField";
import { IExportFormData } from "./type";
import validate from "./validate";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        fieldExportPrivate: {
            marginTop: theme.spacing(2),
        },
        fieldLogoUrl: {
            width: "100%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

interface IProps {
    isLoading: boolean;
    handleOpenDialog: () => void;
}

interface IState {
    version: string;
    logo: string;
}

export enum Version {
    CURRENT = "current",
    LATEST = "latest",
}

export const ExportForm: React.FC<IProps & InjectedFormProps<IExportFormData, IProps>> = (
    props: IProps & InjectedFormProps,
) => {
    const { handleSubmit, handleOpenDialog } = props;
    const classes = useStyles();
    const [disabled, setDisabled] = useState<boolean>(true);
    const urn = useUrn();

    useEffect(() => {
        setDisabled(urn === "");
    }, [urn]);

    return (
        <form onSubmit={handleSubmit}>
            <Field name="version" component={renderRadioExportVersion} disabled={disabled}>
                <Radio value={Version.CURRENT} />
                <Radio value={Version.LATEST} />
            </Field>

            <Field
                className={classes.fieldLogoUrl}
                name="logo"
                component={renderTextField}
                label="Company logo URL"
                placeholder="Insert URL to your company logo"
                disabled={disabled}
            />

            <Box mt={1}>
                <Typography variant="caption" display="block" gutterBottom>
                    Installation guide:
                </Typography>
                <Button variant="contained" color="primary" disabled={disabled} onClick={handleOpenDialog}>
                    See tutorial
                </Button>
            </Box>
        </form>
    );
};

const selector = formValueSelector("exportForm");

const mapStateToProps = (state: IState) => {
    const version = selector(state, "version");
    const logo = selector(state, "logo");

    return {
        version,
        logo,
    };
};

const mapDispatchToProps = {
    change,
};

const form = reduxForm<IExportFormData, IProps>({
    form: "exportForm",
    validate,
    initialValues: { version: Version.CURRENT },
})(connect(mapStateToProps, mapDispatchToProps)(ExportForm));

export default form;
