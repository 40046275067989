import { ThemeProvider } from "@material-ui/styles";
import React, { ReactNode } from "react";
import { StoreProvider } from "../../redux";
import SecurityProvider from "../../redux/keycloack/provider";
import iolabsTheme from "../../utils/Theming";

interface IProps {
    children?: ReactNode;
}

class AppWrapper extends React.Component<IProps> {
    public render() {
        const { children } = this.props;

        return (
            <StoreProvider>
                <SecurityProvider>
                    <ThemeProvider theme={iolabsTheme}>{children}</ThemeProvider>
                </SecurityProvider>
            </StoreProvider>
        );
    }
}

export default AppWrapper;
