import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const renderRadioExportVersion = ({ input, disabled, ...rest }) => (
    <FormControl>
        <RadioGroup {...input} {...rest}>
            <FormControlLabel
                value="current"
                disabled={disabled}
                control={<Radio color="primary" />}
                label="Embedded current version"
            />
            <FormControlLabel
                value="latest"
                disabled={disabled}
                control={<Radio color="primary" />}
                label="Embedded latest version"
            />
        </RadioGroup>
    </FormControl>
);

export default renderRadioExportVersion;
